import { LoadingIndicator } from "@dfp/react-components";
import { keyframes, styled, useTheme } from "@mui/material";
import { ReactElement, SVGProps } from "react";

export function SplashScreen(): ReactElement {
  return (
    <Container>
      <SplashLogo />
    </Container>
  );
}

export function SplashLogo(): ReactElement {
  const theme = useTheme();

  return (
    <SplashLogoContainer>
      <StyledSvgLogo />
      <LoadingIndicator color={theme.palette.primary.main} />
    </SplashLogoContainer>
  );
}

const SplashLogoContainer = styled("div")(() => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  flexDirection: "column",
}));

const Container = styled("div")(() => ({
  position: "fixed",
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  display: "flex",
  justifyContent: "center",
  background: "rgba(0, 0, 0, 0.03)",
  backgroundColor:
    "linear-gradient(160deg, rgba(0, 0, 0, 0.03) 0%, rgba(0, 0, 0, 0.08) 100%)",
  pointerEvents: "none",
}));

const SvgLogo = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 53.121 71.037"
      {...props}
    >
      <path
        id="Path_3103"
        data-name="Path 3103"
        d="M458.908,271.094c-6.65.875-27.792,5.6-20.336,44.159,4.527,23.409,19.514,27.731,29.622,26.4s23.468-9.383,21.783-33.166C487.2,269.308,465.557,270.219,458.908,271.094Zm13.056,37c1.438,12.218-2.418,15.967-6.022,16.441h0c-3.605.474-8.3-2.149-10.072-14.323-2.916-20.034,2.9-21.806,5.276-22.119S469.6,287.988,471.964,308.094Z"
        transform="translate(-437.001 -270.833)"
      />
    </svg>
  );
};

const LogoPulseKeyframes = keyframes`
0% {
  transform: scale3d(1, 1, 1);
}
50% {
  transform: scale3d(1.05, 1.05, 1.05);
}
100% {
  transform: scale3d(1, 1, 1);
}
`;

const StyledSvgLogo = styled(SvgLogo)(({ theme }) => ({
  width: "75px",
  height: "75px",
  fill: theme.palette.primary.main,
  animationTimingFunction: "ease-in-out",
  animationIterationCount: "infinite",
  animationFillMode: "both",
  animationDelay: "1s",
  animationDuration: "1s",
  animationName: LogoPulseKeyframes,
  marginBottom: theme.spacing(2),
}));
