import { useContext } from "react";

import { SnackbarContext } from "./SnackbarProvider";

export function useSnackbar() {
  const context = useContext(SnackbarContext);

  if (context === undefined) {
    throw new Error("useSnackbar must be called inside the 'SnackbarProvider'");
  }

  return context;
}
