import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import {
  DevBar,
  ErrorHandlerProvider,
  SnackbarProvider,
} from "@dfp/react-components";
import CssBaseline from "@mui/material/CssBaseline";
import { useNavigate } from "react-router-dom";

import { API_ENDPOINT_PREFIX, COMMIT_ID, DEV_BAR_COLOR } from "./AppConfig";
import AppRoutes from "./AppRoutes";
import { MsalAccountProvider } from "./Context/MsalAccount/MsalAccountProvider";
import { CustomNavigationClient } from "./Context/UserAuth/msal/CustomNavigationClient";
import { useErrorTracker } from "./Features/AppInsights/useErrorTracker";
import { useErrorTranslator } from "./Hooks/Error";

type Props = {
  pca: PublicClientApplication;
};

function App({ pca }: Props) {
  const navigate = useNavigate();
  const navigationClient = new CustomNavigationClient(navigate);
  pca.setNavigationClient(navigationClient);
  const translateError = useErrorTranslator();
  const trackError = useErrorTracker();

  return (
    <MsalProvider instance={pca}>
      <MsalAccountProvider>
        <DevBar
          color={DEV_BAR_COLOR}
          commitId={COMMIT_ID}
          apiEndpointPrefix={API_ENDPOINT_PREFIX}
        />
        <SnackbarProvider>
          <ErrorHandlerProvider
            translateError={translateError}
            trackError={trackError}
          >
            <CssBaseline />
            <AppRoutes />
          </ErrorHandlerProvider>
        </SnackbarProvider>
      </MsalAccountProvider>
    </MsalProvider>
  );
}

export default App;
